// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserCommentList_addreply__9aVoj{
    color: rgb(95, 95, 235);
    cursor: pointer;
}
.UserCommentList_btext__wcGZF{
    color: black;
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 5px;
}
.UserCommentList_text__Utyte{
    font-size: 14px;
}
.UserCommentList_ctitle__R53hX{
    white-space: nowrap; 
    font-size: 16px;
    font-weight: 700;
    color: rgb(95, 95, 235);
    line-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis; 
    padding-bottom: 10px;
}
.UserCommentList_ctitle__R53hX>span{
    text-decoration-line: underline;
    cursor: pointer;
}
.UserCommentList_content__-\\+weH{
    font-size: 14px;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding-bottom: 10px;
}
.UserCommentList_newreplybox__ihWhI{
    display: flex;
    padding-top: 10px;
    align-items: center;
    margin-bottom: 10px;
}
.UserCommentList_newreplybox__ihWhI>p{
    padding-right: 10px;
    color: black;
}
.UserCommentList_newreplybox__ihWhI>button{
    margin-left: 10px;
}
.UserCommentList_newreplybox__ihWhI>div>div>textarea{
    height: 80px;
}
.UserCommentList_newreply__wlktC{
    width: 100%;
    
}
.UserCommentList_btext2__oLENJ{
    color: rgb(255, 0, 0);
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 5px;
}
.UserCommentList_box__Q3eWi{
    width: 100%;
    padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/views/UserManager/UserCommentList/UserCommentList.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;IACvB,oBAAoB;AACxB;AACA;IACI,+BAA+B;IAC/B,eAAe;AACnB;AACA;IACI,eAAe;IACf,qBAAqB;IACrB,qBAAqB;IACrB,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,YAAY;AAChB;AACA;IACI,WAAW;;AAEf;AACA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,oBAAoB;AACxB","sourcesContent":[".addreply{\n    color: rgb(95, 95, 235);\n    cursor: pointer;\n}\n.btext{\n    color: black;\n    font-weight: 700;\n    font-size: 14px;\n    padding-bottom: 5px;\n}\n.text{\n    font-size: 14px;\n}\n.ctitle{\n    white-space: nowrap; \n    font-size: 16px;\n    font-weight: 700;\n    color: rgb(95, 95, 235);\n    line-height: 26px;\n    overflow: hidden;\n    text-overflow: ellipsis; \n    padding-bottom: 10px;\n}\n.ctitle>span{\n    text-decoration-line: underline;\n    cursor: pointer;\n}\n.content{\n    font-size: 14px;\n    white-space: pre-wrap;\n    word-wrap: break-word;\n    padding-bottom: 10px;\n}\n.newreplybox{\n    display: flex;\n    padding-top: 10px;\n    align-items: center;\n    margin-bottom: 10px;\n}\n.newreplybox>p{\n    padding-right: 10px;\n    color: black;\n}\n.newreplybox>button{\n    margin-left: 10px;\n}\n.newreplybox>div>div>textarea{\n    height: 80px;\n}\n.newreply{\n    width: 100%;\n    \n}\n.btext2{\n    color: rgb(255, 0, 0);\n    font-weight: 700;\n    font-size: 14px;\n    padding-bottom: 5px;\n}\n.box{\n    width: 100%;\n    padding-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addreply": `UserCommentList_addreply__9aVoj`,
	"btext": `UserCommentList_btext__wcGZF`,
	"text": `UserCommentList_text__Utyte`,
	"ctitle": `UserCommentList_ctitle__R53hX`,
	"content": `UserCommentList_content__-+weH`,
	"newreplybox": `UserCommentList_newreplybox__ihWhI`,
	"newreply": `UserCommentList_newreply__wlktC`,
	"btext2": `UserCommentList_btext2__oLENJ`,
	"box": `UserCommentList_box__Q3eWi`
};
export default ___CSS_LOADER_EXPORT___;
