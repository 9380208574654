// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserReviewList_addreply__iYDHo{
    color: rgb(95, 95, 235);
    cursor: pointer;
}
.UserReviewList_btext__niBEB{
    color: black;
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 5px;
}
.UserReviewList_text__hMSnZ{
    font-size: 14px;
}
.UserReviewList_ctitle__nhhyP{
    white-space: nowrap; 
    font-size: 16px;
    font-weight: 700;
    color: rgb(95, 95, 235);
    line-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis; 
    padding-bottom: 10px;
}
.UserReviewList_ctitle__nhhyP>span{
    text-decoration-line: underline;
    cursor: pointer;
}
.UserReviewList_content__SDdPZ{
    font-size: 14px;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding-bottom: 10px;
}
.UserReviewList_newreplybox__LKMJQ{
    display: flex;
    padding-top: 10px;
    align-items: center;
    margin-bottom: 10px;
}
.UserReviewList_newreplybox__LKMJQ>p{
    padding-right: 10px;
    color: black;
}
.UserReviewList_newreplybox__LKMJQ>button{
    margin-left: 10px;
}
.UserReviewList_newreplybox__LKMJQ>div>div>textarea{
    height: 80px;
}
.UserReviewList_newreply__jg2xw{
    width: 100%;
    
}
.UserReviewList_btext2__tMiNK{
    color: rgb(255, 0, 0);
    font-weight: 700;
    font-size: 14px;
    padding-bottom: 5px;
}
.UserReviewList_box__geK64{
    width: 100%;
    padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/views/UserManager/UserReviewList/UserReviewList.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;IACvB,oBAAoB;AACxB;AACA;IACI,+BAA+B;IAC/B,eAAe;AACnB;AACA;IACI,eAAe;IACf,qBAAqB;IACrB,qBAAqB;IACrB,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,YAAY;AAChB;AACA;IACI,WAAW;;AAEf;AACA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,oBAAoB;AACxB","sourcesContent":[".addreply{\n    color: rgb(95, 95, 235);\n    cursor: pointer;\n}\n.btext{\n    color: black;\n    font-weight: 700;\n    font-size: 14px;\n    padding-bottom: 5px;\n}\n.text{\n    font-size: 14px;\n}\n.ctitle{\n    white-space: nowrap; \n    font-size: 16px;\n    font-weight: 700;\n    color: rgb(95, 95, 235);\n    line-height: 26px;\n    overflow: hidden;\n    text-overflow: ellipsis; \n    padding-bottom: 10px;\n}\n.ctitle>span{\n    text-decoration-line: underline;\n    cursor: pointer;\n}\n.content{\n    font-size: 14px;\n    white-space: pre-wrap;\n    word-wrap: break-word;\n    padding-bottom: 10px;\n}\n.newreplybox{\n    display: flex;\n    padding-top: 10px;\n    align-items: center;\n    margin-bottom: 10px;\n}\n.newreplybox>p{\n    padding-right: 10px;\n    color: black;\n}\n.newreplybox>button{\n    margin-left: 10px;\n}\n.newreplybox>div>div>textarea{\n    height: 80px;\n}\n.newreply{\n    width: 100%;\n    \n}\n.btext2{\n    color: rgb(255, 0, 0);\n    font-weight: 700;\n    font-size: 14px;\n    padding-bottom: 5px;\n}\n.box{\n    width: 100%;\n    padding-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addreply": `UserReviewList_addreply__iYDHo`,
	"btext": `UserReviewList_btext__niBEB`,
	"text": `UserReviewList_text__hMSnZ`,
	"ctitle": `UserReviewList_ctitle__nhhyP`,
	"content": `UserReviewList_content__SDdPZ`,
	"newreplybox": `UserReviewList_newreplybox__LKMJQ`,
	"newreply": `UserReviewList_newreply__jg2xw`,
	"btext2": `UserReviewList_btext2__tMiNK`,
	"box": `UserReviewList_box__geK64`
};
export default ___CSS_LOADER_EXPORT___;
