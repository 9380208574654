import { AxiosResponse } from "axios";
import { api } from "../library/axios";
import { API_ROUTE } from "../const/apiRoute";
import { HanderResponse } from "../_base/helper/FunctionHelper";
import { NotificationExtension } from "../_base/extension/NotificationExtension";
import { isNullOrUndefined } from "../_base/extension/StringExtension";



export const createPegaHtml = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await api.post(
      API_ROUTE.CREATE_PAGE,
      data
    );
    if (!isNullOrUndefined(response) && response?.data?.success) {
      NotificationExtension.Success("Thêm mới thành công !");
    } else if (response != null)
      NotificationExtension.Fails("Thêm mới thất bại !");
    return response.data;
  } catch (error) {
    HanderResponse(error);
  }
};


export const editPage = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await api.post(
      API_ROUTE.EDIT_PAGE,
      data
    );
    if (!isNullOrUndefined(response) && response?.data?.success) {
      NotificationExtension.Success(
        "Chỉnh sửa lịch hẹn bảo hành thành công"
      );
    } else if (response != null)
      NotificationExtension.Fails("Chỉnh sửa lịch hẹn bảo hành thất bại !");
    return response.data;
  } catch (error) {
    HanderResponse(error);
  }
};


export const getlistPage = async (query: string): Promise<any> => {
  try {
    const response: AxiosResponse = await api.get(
      `${API_ROUTE.GET_LIST}?${query}`
    );
    return response.data;
  } catch (error) {
    HanderResponse(error);
  }
};





