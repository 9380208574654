// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categoryFeatureView_tab__dfiQo {
    position: relative;
    border: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
  
    &:first-of-type {
      border-radius: rem(4px) 0 0 rem(4px);
  
      @mixin rtl {
        border-radius: 0 rem(4px) rem(4px) 0;
      }
    }
  
    &:last-of-type {
      border-radius: 0 rem(4px) rem(4px) 0;
  
      @mixin rtl {
        border-radius: rem(4px) 0 0 rem(4px);
      }
    }
  
    & + & {
      border-left-width: 0;
  
      @mixin rtl {
        border-right-width: 0;
        border-left-width: rem(1px);
      }
    }
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5));
    }
  
    &[data-active] {
      z-index: 1;
      background-color: var(--mantine-color-blue-filled);
      border-color: var(--mantine-color-blue-filled);
      color: var(--mantine-color-white);
  
      @mixin hover {
        background-color: var(--mantine-color-blue-filled-hover);
      }
    }
  }`, "",{"version":3,"sources":["webpack://./src/views/Category/CategoryFeatureView/categoryFeatureView.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,2FAA2F;IAC3F,qFAAqF;;IAErF;MACE,oCAAoC;;MAEpC;QACE,oCAAoC;MACtC;IACF;;IAEA;MACE,oCAAoC;;MAEpC;QACE,oCAAoC;MACtC;IACF;;IAEA;MACE,oBAAoB;;MAEpB;QACE,qBAAqB;QACrB,2BAA2B;MAC7B;IACF;;IAEA;MACE,sFAAsF;IACxF;;IAEA;MACE,UAAU;MACV,kDAAkD;MAClD,8CAA8C;MAC9C,iCAAiC;;MAEjC;QACE,wDAAwD;MAC1D;IACF;EACF","sourcesContent":[".tab {\n    position: relative;\n    border: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));\n    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));\n  \n    &:first-of-type {\n      border-radius: rem(4px) 0 0 rem(4px);\n  \n      @mixin rtl {\n        border-radius: 0 rem(4px) rem(4px) 0;\n      }\n    }\n  \n    &:last-of-type {\n      border-radius: 0 rem(4px) rem(4px) 0;\n  \n      @mixin rtl {\n        border-radius: rem(4px) 0 0 rem(4px);\n      }\n    }\n  \n    & + & {\n      border-left-width: 0;\n  \n      @mixin rtl {\n        border-right-width: 0;\n        border-left-width: rem(1px);\n      }\n    }\n  \n    @mixin hover {\n      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5));\n    }\n  \n    &[data-active] {\n      z-index: 1;\n      background-color: var(--mantine-color-blue-filled);\n      border-color: var(--mantine-color-blue-filled);\n      color: var(--mantine-color-white);\n  \n      @mixin hover {\n        background-color: var(--mantine-color-blue-filled-hover);\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab": `categoryFeatureView_tab__dfiQo`
};
export default ___CSS_LOADER_EXPORT___;
