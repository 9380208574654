// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.articlelist_contentbox__7koXN {
  padding: 10px 0px;
  width: 100%;
}
.articlelist_contentbox__7koXN > div {
  width: 30%;
}
.articlelist_title__0cs8u {
  color: rgb(0, 0, 238);
  font-size: 16px;
  padding-bottom: 5px;
}
.articlelist_summary__HM9hx {
  font-size: 14px;
  padding-bottom: 10px;
  padding-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Article/ArticleList/articlelist.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,UAAU;AACZ;AACA;EACE,qBAAqB;EACrB,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,oBAAoB;EACpB,iBAAiB;AACnB","sourcesContent":[".contentbox {\n  padding: 10px 0px;\n  width: 100%;\n}\n.contentbox > div {\n  width: 30%;\n}\n.title {\n  color: rgb(0, 0, 238);\n  font-size: 16px;\n  padding-bottom: 5px;\n}\n.summary {\n  font-size: 14px;\n  padding-bottom: 10px;\n  padding-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentbox": `articlelist_contentbox__7koXN`,
	"title": `articlelist_title__0cs8u`,
	"summary": `articlelist_summary__HM9hx`
};
export default ___CSS_LOADER_EXPORT___;
