// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_treeTable__DJVh6 {
  width: 800px;
  border: 1px solid black;
  td,
  th {
    padding: 5px 20px 5px 5px;
  }
  .home_header__s4siO {
    font-weight: bold;
    border: 1px solid black;
    td {
      padding: 5px;
      text-align: center;
      align-items: center;
    }
  }
  .home_name__kZeJ0 {
    font-weight: 600;
  }
  .home_expanded__HqHYV {
    color: blue;
    font-weight: 600;
  }
  .home_viewPage__13Jt9,
  .home_visibleType__KHpI4,
  .home_idCate__VdzYE {
    position: absolute;
  }
  .home_viewPage__13Jt9 {
    left: 39%;
  }
  .home_visibleType__KHpI4 {
    left: 70%;
  }
  .home_idCate__VdzYE {
    left: 94%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/Category/home.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAuB;EACvB;;IAEE,yBAAyB;EAC3B;EACA;IACE,iBAAiB;IACjB,uBAAuB;IACvB;MACE,YAAY;MACZ,kBAAkB;MAClB,mBAAmB;IACrB;EACF;EACA;IACE,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,gBAAgB;EAClB;EACA;;;IAGE,kBAAkB;EACpB;EACA;IACE,SAAS;EACX;EACA;IACE,SAAS;EACX;EACA;IACE,SAAS;EACX;AACF","sourcesContent":[".treeTable {\n  width: 800px;\n  border: 1px solid black;\n  td,\n  th {\n    padding: 5px 20px 5px 5px;\n  }\n  .header {\n    font-weight: bold;\n    border: 1px solid black;\n    td {\n      padding: 5px;\n      text-align: center;\n      align-items: center;\n    }\n  }\n  .name {\n    font-weight: 600;\n  }\n  .expanded {\n    color: blue;\n    font-weight: 600;\n  }\n  .viewPage,\n  .visibleType,\n  .idCate {\n    position: absolute;\n  }\n  .viewPage {\n    left: 39%;\n  }\n  .visibleType {\n    left: 70%;\n  }\n  .idCate {\n    left: 94%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"treeTable": `home_treeTable__DJVh6`,
	"header": `home_header__s4siO`,
	"name": `home_name__kZeJ0`,
	"expanded": `home_expanded__HqHYV`,
	"viewPage": `home_viewPage__13Jt9`,
	"visibleType": `home_visibleType__KHpI4`,
	"idCate": `home_idCate__VdzYE`
};
export default ___CSS_LOADER_EXPORT___;
