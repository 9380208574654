// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavbarLinksGroup_control__5HG-O {
    font-weight: 500;
    display: block;
    width: 100%;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
    color: var(--mantine-color-text);
    font-size: var(--mantine-font-size-sm);
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
      color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
    }
  }
  
  .NavbarLinksGroup_link__YY1kI {
    font-weight: 500;
    display: block;
    text-decoration: none;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
    padding-left: var(--mantine-spacing-md);
    margin-left: var(--mantine-spacing-xl);
    font-size: var(--mantine-font-size-sm);
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
    border-left: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
      color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
    }
  }
  
  .NavbarLinksGroup_chevron__T4TW0 {
    transition: transform 200ms ease;
  }`, "",{"version":3,"sources":["webpack://./src/_base/_style/NavbarLinksGroup.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,WAAW;IACX,4DAA4D;IAC5D,gCAAgC;IAChC,sCAAsC;;IAEtC;MACE,sFAAsF;MACtF,0EAA0E;IAC5E;EACF;;EAEA;IACE,gBAAgB;IAChB,cAAc;IACd,qBAAqB;IACrB,4DAA4D;IAC5D,uCAAuC;IACvC,sCAAsC;IACtC,sCAAsC;IACtC,2EAA2E;IAC3E,2FAA2F;;IAE3F;MACE,sFAAsF;MACtF,0EAA0E;IAC5E;EACF;;EAEA;IACE,gCAAgC;EAClC","sourcesContent":[".control {\n    font-weight: 500;\n    display: block;\n    width: 100%;\n    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);\n    color: var(--mantine-color-text);\n    font-size: var(--mantine-font-size-sm);\n  \n    @mixin hover {\n      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));\n      color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));\n    }\n  }\n  \n  .link {\n    font-weight: 500;\n    display: block;\n    text-decoration: none;\n    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);\n    padding-left: var(--mantine-spacing-md);\n    margin-left: var(--mantine-spacing-xl);\n    font-size: var(--mantine-font-size-sm);\n    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));\n    border-left: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));\n  \n    @mixin hover {\n      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));\n      color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));\n    }\n  }\n  \n  .chevron {\n    transition: transform 200ms ease;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": `NavbarLinksGroup_control__5HG-O`,
	"link": `NavbarLinksGroup_link__YY1kI`,
	"chevron": `NavbarLinksGroup_chevron__T4TW0`
};
export default ___CSS_LOADER_EXPORT___;
