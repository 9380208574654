// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Editview_formInputStatus__IBiQX {
  margin: 10px;
  padding: 25px;
  background-color: rgb(251, 251, 202);
  border: 1px solid;
}

.Editview_titleH1__3qaB6 {
  font-weight: 700;
  font-size: 16px;
  color: red;
  margin-top: 8px;
}

.Editview_errorText__ixguq {
  color: red;
  font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Sell/OrderList/EditView/Editview.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,oCAAoC;EACpC,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,eAAe;AACjB","sourcesContent":[".formInputStatus {\n  margin: 10px;\n  padding: 25px;\n  background-color: rgb(251, 251, 202);\n  border: 1px solid;\n}\n\n.titleH1 {\n  font-weight: 700;\n  font-size: 16px;\n  color: red;\n  margin-top: 8px;\n}\n\n.errorText {\n  color: red;\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formInputStatus": `Editview_formInputStatus__IBiQX`,
	"titleH1": `Editview_titleH1__3qaB6`,
	"errorText": `Editview_errorText__ixguq`
};
export default ___CSS_LOADER_EXPORT___;
